import React, { FC } from 'react'
import styled from 'styled-components'
import PageWrapper from '../components/page-wrapper'

const StyMessage = styled.div`
  font-size: 4rem;
  text-align: center;
`

const Error404: FC<{}> = () => (
  <PageWrapper>
    <StyMessage>
      Not found
    </StyMessage>
  </PageWrapper>
)

export default Error404
